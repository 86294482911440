/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import getPath from '@helper_getpath';
import { getResolver, setResolver } from '@helper_localstorage';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useRef } from 'react';
import genereateCmsMenu from '@core_modules/theme/components/header/components/HeaderA/genereateCmsMenu';

const MenuChildren = dynamic(() => import('@core_modules/theme/components/header/components/HeaderA/mcategoryChildren'), { ssr: true });

const Menu = (props) => {
    const {
        data, cmsMenu = '', className = '', t, useOthers = false,
    } = props;
    // WIP : Custom Header Menu
    // const cmsPages = storeConfig && storeConfig.cms_page ? storeConfig.cms_page.split(',') : [];
    let menu = data?.categories?.items[0]?.children;
    if (!menu) {
        menu = [];
    }

    if (cmsMenu) {
        const dataMenu = genereateCmsMenu(cmsMenu);
        if (dataMenu && dataMenu.length) {
            menu = [...menu, ...dataMenu];
        }
    }

    if (menu.filter((item) => item.include_in_menu).length > 7 && useOthers) {
        const firstMenu = menu.filter((item, key) => key < 8);
        const otherChildren = menu.filter((item, key) => key >= 8);
        const othersMenu = {
            uid: `NavOther-${Date.now()}`,
            name: t('common:menu:others'),
            level: 2,
            path: '#',
            url_path: '#',
            url_key: '#',
            include_in_menu: otherChildren.length,
            children: otherChildren,
        };

        menu = [...firstMenu, othersMenu];
    }

    const generateLink = (cat) => {
        let link;
        if (cat.link) {
            if (cat.link.includes('http') || cat.link.includes('https')) {
                link = getPath(cat.link);
            } else {
                link = `/${cat.link}`;
            }
        } else if (cat.url_path.includes('http') || cat.url_path.includes('https')) {
            link = cat.url_path;
        } else {
            link = `/${cat.url_path}`;
        }

        if (cat.customLink) {
            if (cat.url_path.includes('http') || cat.url_path.includes('https')) {
                return [cat.url_path, cat.url_path];
            }
            return [`/${cat.url_path}`, `/${cat.url_path}`];
        }
        return [link, link];
    };
    const handleClick = async (cat) => {
        let link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;

        if (cat?.customLink) {
            link = cat.url_path;
        }

        const urlResolver = getResolver();
        // WIP : Custom Header Menu
        // if (storeConfig.pwa.ves_menu_enable) {
        //     if (cat.link_type === 'category_link') {
        //         urlResolver[link] = {
        //             type: 'CATEGORY',
        //             id: cat.category_id,
        //         };
        //         await setResolver(urlResolver);
        //     } else {
        //         const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
        //         if (cms) {
        //             urlResolver[link] = {
        //                 type: 'CMS_PAGE',
        //             };
        //             await setResolver(urlResolver);
        //         }
        //     }
        // } else {
        //     urlResolver[link] = {
        //         type: 'CATEGORY',
        //         id: cat.uid,
        //     };
        //     await setResolver(urlResolver);
        // }
        urlResolver[link] = {
            type: 'CATEGORY',
            id: cat.uid,
        };
        await setResolver(urlResolver);
    };

    const linkEl = useRef([]);
    const megaMenuRef = useRef([]);

    return (
        <>
            <ul className={cx('nav swift-nav-menubar flex justify-between pt-4', className)} role="menubar" id="header-nav-menubar">
                {menu.map((val, idx) => {
                    if (val.include_in_menu && val.name) {
                        let prefix = '';

                        prefix += ` ${val.name} `;

                        const generatedLink = generateLink(val);

                        return (
                            <li
                                key={idx}
                                role="menuitem"
                                id={`header-menuitem-${idx}`}
                                onMouseEnter={() => {
                                    if (megaMenuRef && val.dropdown_animation_in) {
                                        megaMenuRef.current[idx].classList.add('animate__animated');
                                        megaMenuRef.current[idx].classList.add(`animate__${val.dropdown_animation_in}`);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (megaMenuRef && val.dropdown_animation_in) {
                                        megaMenuRef.current[idx].classList.remove('animate__animated');
                                        megaMenuRef.current[idx].classList.remove(`animate__${val.dropdown_animation_in}`);
                                    }
                                }}
                                className={cx(
                                    'swift-nav-menu-lv1',
                                    'text-sm',
                                    'tracking-normal',
                                    'text-neutral-white',
                                    'nav-column-first',
                                )}
                            >
                                {val.link && val.link !== '#' ? (
                                    <>
                                        {val.before_html && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: val.before_html,
                                                }}
                                            />
                                        )}
                                        <Link
                                            href={{
                                                pathname: generatedLink[0],
                                                query: generatedLink[1],
                                            }}
                                            as={generatedLink[1]}
                                            prefetch={false}
                                            legacyBehavior
                                        >
                                            <a
                                                onClick={() => handleClick(val)}
                                                // eslint-disable-next-line no-return-assign
                                                ref={(el) => (linkEl.current[idx] = el)}
                                                dangerouslySetInnerHTML={{
                                                    __html: prefix !== '' ? `${prefix}` : val.name,
                                                }}
                                                onMouseEnter={() => {
                                                    if (val.caret) {
                                                        // eslint-disable-next-line max-len
                                                        linkEl.current[idx].innerHTML = linkEl.current[idx].innerHTML.replace(
                                                            val.caret,
                                                            val.hover_caret,
                                                        );
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if (val.hover_caret) {
                                                        // eslint-disable-next-line max-len
                                                        linkEl.current[idx].innerHTML = linkEl.current[idx].innerHTML.replace(
                                                            val.hover_caret,
                                                            val.caret,
                                                        );
                                                    }
                                                }}
                                                className="a-link-first font-bold"
                                            />
                                        </Link>
                                        {val.after_html && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: val.after_html,
                                                }}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Link key={idx} href={generateLink(val)[0]} as={generateLink(val)[1]} prefetch={false} legacyBehavior>
                                        <a
                                            onClick={() => handleClick(val)}
                                            dangerouslySetInnerHTML={{
                                                __html: prefix !== '' ? `${prefix}` : val.name,
                                            }}
                                            className="a-link-first font-bold"
                                        />
                                    </Link>
                                )}

                                {val.children.length > 0 ? (
                                    <div
                                        className={cx('mega-menu', 'z-10', 'swift-megamenu')}
                                        aria-hidden="true"
                                        role="menu"
                                        // eslint-disable-next-line no-return-assign
                                        ref={(el) => (megaMenuRef.current[idx] = el)}
                                    >
                                        <div className={cx('grid', 'bg-neutral-white', 'shadow-md', 'grid-cols-1', 'rounded-lg', 'mega-menu-body')}>
                                            {val.show_header && (
                                                <div className="header-html grid">
                                                    <CmsRenderer content={val.header_html} />
                                                </div>
                                            )}
                                            <MenuChildren data={val.children} handleClick={handleClick} generateLink={generateLink} mainData={val} />
                                            {val.show_footer && (
                                                <div className="footer-html grid">
                                                    <CmsRenderer content={val.footer_html} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {/* {styles} */}
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
            <style jsx global>
                {`
                    .main-content {
                        display: flex;
                    }

                    /* menu list */
                    .nav > li {
                        float: left;
                        font-family: "Plus Jakarta Sans Bold", sans-serif;
                    }

                    /* menu dropdown */
                    .mega-menu-body {
                        border: 1px solid #ddd;
                        border-radius: 0 0 3px 3px;
                        width: 100%;
                        left: 0;
                        margin-left: 0%;
                        grid-template-columns: 1fr;
                    }
                    .mega-menu {
                        background: transparent;
                        opacity: 0;
                        position: absolute;
                        visibility: hidden;
                        width: 100%;
                        left: 0;
                        margin-left: 0%;
                    }
                    li:hover > .mega-menu {
                        opacity: 1;
                        overflow: visible;
                        visibility: visible;
                        padding-top: 14px;
                    }

                    /* menu content */
                    .nav-column a {
                        font-weight: 700;
                        line-height: 1.75;
                        margin: 0;
                        padding: 7px;
                        font-size: 16px;
                    }
                    .nav-column a:hover {
                    }

                    .nav-column a::after {
                        content: '';
                        display: block;
                        margin-top: 3px;
                        width: 100%;
                        height: 2px;
                        background: #000;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.2s ease-out;
                    }
                    .nav-column a:hover::after {
                        visibility: visible;
                        opacity: 1;
                        transition: all 0.2s ease-in;
                    }

                    .a-link-first {
                        position: relative;
                    }

                    .a-link-first::after {
                        content: '';
                        display: block;
                        position: absolute;
                        margin-top: 3px;
                        width: 100%;
                        height: 2px;
                        background: #fff;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.2s ease-out;
                        top: 100%;
                    }
                    .a-link-first:hover::after {
                        visibility: visible;
                        opacity: 1;
                        transition: all 0.2s ease-in;
                    }
                `}
            </style>
        </>
    );
};

export default Menu;
